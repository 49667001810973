<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav title="职业证书报名" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="worktype">
      <div class="order_r">
        <div class="order_l">申报职业（工种）</div>
        <div :class="goodsname != undefined ? 'order_z' : 'order_r'">
          {{ goodsname }}
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">申报等级</div>
        <div
          :class="grade != '请选择' ? 'order_z' : 'order_r'"
          @click="grades()"
        >
          {{ grade }}<van-icon name="arrow" />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">补贴费用</div>
        <div :class="pardes.subsidy != undefined ? 'order_z' : 'order_r'">
          <span style="color:#f00;"
            >￥{{ pardes.subsidy == undefined ? "--" : pardes.subsidy }}元</span
          >
        </div>
      </div>
      <!-- <div class="order_r">
        <div class="page_l">
          补贴费用
          <span
            >￥{{ pardes.subsidy == undefined ? "--" : pardes.subsidy }}元</span
          >
        </div>
        <div class="page_r">
          报名费用
          <span>￥{{ pardes.price == undefined ? "--" : pardes.price }}元</span>
        </div>
      </div> -->
    </div>

    <!-- 基础信息 -->
    <div class="titles">基础信息</div>
    <div class="basics">
      <!-- 修改取消注释 -->
      <!-- <div class="imasg" @click="onimages(1)">
        <div class="upload-img">
          <van-uploader
            :after-read="onReads"
            accept="image/*"
            style="width: 100%; height: 140px"
          >
            <img
              v-if="imga == ''"
              src="@/assets/images/signup/yizp@2x.png"
              style="width: 100%; height: 140px; border-radius: 3px"
            />
            <img
              v-else
              :src="imga"
              style="width: 100%; height: 140px; border-radius: 3px"
            />
          </van-uploader>
        </div>
      </div> -->
      <div class="leftbas">
        <div class="order_r">
          <van-field
            class="fields"
            v-model="name"
            required
            label="姓名"
            placeholder="请输入"
            input-align="right"
          />
        </div>
        <div class="order_r">
          <div class="order_l"><span style="color: #f00">*</span>性别</div>
          <div
            :class="sex == '请选择' ? 'order_r' : 'order_z'"
            style="padding-right: 10px"
            @click="sexshow = true"
          >
            {{ sex }}<van-icon name="arrow" />
          </div>
        </div>
        <div class="order_r">
          <van-field
            class="fields"
            v-model="phone"
            required
            label="联系电话"
            placeholder="请输入"
            input-align="right"
            maxlength="11"
            type="number"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>文化程度</div>
        <div
          :class="culture == '请选择' ? 'order_r' : 'order_z'"
          style="padding-right: 10px"
          @click="cultureshow = true"
        >
          {{ culture }}<van-icon name="arrow" />
        </div>
      </div>
      <div class="order_r">
        <van-field
          class="fields"
          v-model="idcardno"
          required
          label="身份证号"
          placeholder="请输入"
          input-align="right"
          maxlength="18"
          onkeyup="this.value=this.value.replace(/[^\X0-9]/g, '')"
        />
      </div>
      <div class="idimg">
        <div class="left_img">
          <div class="top_com">
            本人身份证人像面照片<span
              style="color: #0297fd; text-decoration: underline"
              @click="showzzs()"
              >（查看示例）</span
            >
          </div>
          <div class="upload-img" @click="onimages(2)">
            <van-uploader
              :after-read="onReads"
              accept="image/*"
              style="width: 100%; height: 100px"
            >
              <img
                v-if="imgb == ''"
                src="@/assets/images/signup/renxiang@2x.png"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
              <img
                v-else
                :src="imgb"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
            </van-uploader>
          </div>
        </div>
        <div class="left_img">
          <div class="top_com">
            本人身份证国徽面照片<span
              style="color: #0297fd; text-decoration: underline"
              @click="showzzs()"
              >（查看示例）</span
            >
          </div>
          <div class="upload-img" @click="onimages(3)">
            <van-uploader
              :after-read="onReads"
              accept="image/*"
              style="width: 100%; height: 100px"
            >
              <img
                v-if="imgc == ''"
                src="@/assets/images/signup/guohui@2x.png"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
              <img
                v-else
                :src="imgc"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
            </van-uploader>
          </div>
        </div>
      </div>
    </div>

    <!-- 工作单位信息 -->
    <!-- 修改取消注释 -->
    <!-- <div class="titles">工作单位信息</div>
    <div class="gzdwxx">
      <div class="order_r">
        <div class="order_l">
          <span style="color: #f00">*</span>工作单位名称
        </div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="companyname"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">
          <span style="color: #f00">*</span>现从事岗位（年限）
        </div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="years"
            placeholder="如‘客户服务一年’"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">
          <span style="color: #f00">*</span>单位人资部门电话
        </div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="telephone"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>单位地址</div>
        <div
          :class="city == '请选择' ? 'order_r' : 'order_z'"
          style="padding-right: 10px"
          @click="cityshow = true"
        >
          {{ city }}<van-icon name="arrow" />
        </div>
      </div>
      <van-field
        v-model="address"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入详细地址"
      />
    </div> -->

    <!-- 现有职业证书（选填） -->
    <!-- 修改取消注释 -->
    <!-- <div class="titles">现有职业证书（选填）</div>
    <div class="gzdwxx">
      <div class="order_r">
        <div class="order_l">现有职业证书名称等级</div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="zsgrade"
            placeholder="如‘客户服务管理师三级’"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">证书编号</div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="zsnumber"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">取证时间</div>
        <div
          :class="zstime == '请选择' ? 'order_r' : 'order_z'"
          style="padding-right: 10px"
          @click="zstimeshow = true"
        >
          {{ zstime }}<van-icon name="arrow" />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">发证机构名称</div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="zsjgname"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
    </div> -->

    <!-- 工作/培训经历 -->
    <!-- 修改取消注释 -->
    <!-- <div class="titlese">
      <div class="ones">工作/培训经历</div>
      <div class="teos">
        要和社保证明上的工作单位相符，点击<span @click="chakn()"
          >查看查询方式</span
        >
      </div>
    </div>
    <div
      v-for="(item, index) in experience"
      :key="index"
      :class="index == 0 ? 'gzdwxx bianlis' : 'gzdwxx bianli'"
    >
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>开始时间</div>
        <div
          :class="item.begindate == '请选择' ? 'order_r' : 'order_z'"
          style="padding-right: 10px"
          @click="timese(index)"
        >
          {{ item.begindate }}<van-icon name="arrow" />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>结束时间</div>
        <div
          :class="item.enddate == '请选择' ? 'order_r' : 'order_z'"
          style="padding-right: 10px"
          @click="timeseend(index)"
        >
          {{ item.enddate }}<van-icon name="arrow" />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>单位名称</div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="item.unitname"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l">
          <span style="color: #f00">*</span>从事何种职业（工种）或学习
        </div>
        <div class="order_r files" style="width: 35%">
          <van-field
            class="fields"
            v-model="item.occupation"
            placeholder="如‘客户服务一年’"
            input-align="right"
          />
        </div>
      </div>
      <div class="order_r">
        <div class="order_l"><span style="color: #f00">*</span>证明人</div>
        <div class="order_r files">
          <van-field
            class="fields"
            v-model="item.witness"
            placeholder="请输入"
            input-align="right"
          />
        </div>
      </div>
      
    </div>

      <van-popup
        v-model="begtimeshow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          @confirm="confirmFn(es)"
          @cancel="begtimeshow = false"
        />
      </van-popup>
      <van-popup
        v-model="endtimeshow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          @confirm="confirmFnend(es)"
          @cancel="endtimeshow = false"
        />
      </van-popup>
    <div class="addto" @click="addto()">
      <img src="@/assets/images/signup/jia(1)@2x.png" alt="" />添加工作/培训经历
    </div> -->

    <!-- 其他报名材料 -->
    <!-- 修改取消注释 -->
    <!-- <div class="titles">其他报名材料</div>
    <div class="basics">
      <div class="idimg">
        <div class="left_img">
          <div class="top_com">
            学历证明<span
              style="color: #0297fd; text-decoration: underline"
              @click="chakns((show = true))"
              >（查看示例）</span
            >
          </div>
          <div class="upload-img" @click="onimages(4)">
            <van-uploader
              :after-read="onReads"
              accept="image/*"
              style="width: 100%; height: 100px"
            >
              <img
                v-if="imgd == ''"
                src="@/assets/images/signup/xlzm@2x.png"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
              <img
                v-else
                :src="imgd"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
            </van-uploader>
          </div>
        </div>
        <div class="left_img">
          <div class="top_com">
            社保证明<span
              style="color: #0297fd; text-decoration: underline"
              @click="chakn()"
              >（查看示例）</span
            >
          </div>
          <div class="upload-img" @click="onimages(5)">
            <van-uploader
              :after-read="onReads"
              accept="image/*"
              style="width: 100%; height: 100px"
            >
              <img
                v-if="imge == ''"
                src="@/assets/images/signup/sbzm@2x.png"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
              <img
                v-else
                :src="imge"
                style="width: 100%; height: 100px; border-radius: 3px"
              />
            </van-uploader>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 底部复选框 -->
    <div class="checkbox">
      <van-checkbox v-model="checked"
        >本人自愿参加职业技能等级认定，承诺符合申报技能等级的条件，提供的信息资料真实有效。</van-checkbox
      >
    </div>

    <!-- 确认报名 -->
    <div class="btn">
      <button class="butts" @click="btn()">确认报名</button>
    </div>

    <!-- 选择器与图片预览 -->
    <div>
      <van-popup
        v-model="zstimeshow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          @confirm="confirmFntime"
          @cancel="zstimeshow = false"
        />
      </van-popup>
      <!-- 申报登记选择器 -->
      <van-popup
        v-model="pickershow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          title="申报等级"
          show-toolbar
          :columns="columns"
          @confirm="onConfirmgrade"
        />
      </van-popup>
      <!-- 性别选择器 -->
      <van-popup v-model="sexshow" position="bottom" :style="{ height: '50%' }">
        <van-picker
          title="性别"
          show-toolbar
          :columns="columnssex"
          @confirm="onConfirmgradesex"
        />
      </van-popup>
      <!-- 文化程度选择器 -->
      <van-popup
        v-model="cultureshow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          title="性别"
          show-toolbar
          :columns="columnsculture"
          @confirm="onConfirmgradeculture"
        />
      </van-popup>
      <!-- 地址选择器 -->
      <van-popup
        v-model="cityshow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <!-- <van-picker
        title="单位地址"
        show-toolbar
        :columns="areaList"
        @confirm="onConfirmgradecity"
      /> -->
        <van-area
          title="单位地址"
          :area-list="areaList"
          @confirm="onConfirmgradecity"
        />
      </van-popup>

      <!-- 弹窗 -->
      <!-- 社保证明查询方式 -->
      <van-dialog
        class="dialog"
        v-model="showa"
        title="社保证明查询方式"
        :showCancelButton="false"
        show-cancel-button
        confirmButtonColor="#333333"
        @confirm="confirm"
      >
        <div class="com_d">
          <div>
            支付宝→市民中心→社保→人社服务→证明验证→证明材料申请→企业养老保险个人参保证明
          </div>
          <img
            class="images"
            @click="ImagePre(0)"
            src="@/assets/images/signup/sbzmcxff@2x.png"
            alt=""
          />
          <div class="sty">社保证明样式</div>
          <div class="zhuy">
            注意：表中工作经历时间与单位务必与社保证明时间和单位完全一致。写最近的工作经历即可，需满报名要求年限。
          </div>
          <img
            class="imag"
            @click="ImagePre(1)"
            src="@/assets/images/signup/sbzmys@2x.png"
            alt=""
          />
        </div>
      </van-dialog>
      <!-- 学历证明查询方式 -->
      <van-dialog
        class="dialog"
        v-model="show"
        title="学历证明查询方式"
        :showCancelButton="false"
        show-cancel-button
        confirmButtonColor="#333333"
        @confirm="confirm"
      >
        <div class="com_d">
          <div class="zhuy">
            注意：2001年以后毕业的需要提供学信网查询证明或学历认证报告（学信网官网：https://www.chsi.com.cn/）；如毕业时间早无法查询认证，需毕业证书放置桌上连同桌面一起拍照。
          </div>
          <img
            class="images"
            @click="ImagePre(2)"
            src="@/assets/images/signup/图层 6@2x.png"
            alt=""
          />
          <div class="sty">学历证明样式</div>
          <img
            class="imag"
            @click="ImagePre(3)"
            src="@/assets/images/signup/xlbg-sample-2018@2x.png"
            alt=""
          />
        </div>
      </van-dialog>
      <!-- 身份证上传示例 -->
      <van-dialog
        class="dialog"
        v-model="showzz"
        title="身份证上传示例"
        :showCancelButton="false"
        show-cancel-button
        confirmButtonColor="#333333"
        @confirm="confirm"
      >
        <div class="com_d">
          <img
            class="images"
            @click="ImagePre(4)"
            src="@/assets/images/sfzsc.png"
            alt=""
          />
          <div class="zhuy">
            温馨提示：请在光线充足的情况下进行拍摄，保证四角完整无遮挡，信息清晰。
          </div>
        </div>
      </van-dialog>
      <!-- 图片预览 -->
      <van-image-preview
        v-model="shows"
        :startPosition="startPos"
        :images="images"
      >
      </van-image-preview>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";

// import floatBtn from "@/components/floatBtn.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Picker,
  RadioGroup,
  Radio,
  ImagePreview,
  Popup,
  Field,
  Uploader,
  Area,
  DatetimePicker,
  Checkbox,
  CheckboxGroup,
} from "vant";
import {
  getimg,
  getskillgoodsconditionfind,
  getskillgoodslevelfind,
  getskillgoodscondition,
  orderadd,
  checkorder,
  indexs,
  info
} from "@/network/index.js";
import canvasDataURL from "../../assets/js/img.js";
import { areaList } from "@vant/area-data";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Area.name]: Area,
    [DatetimePicker.name]: DatetimePicker,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      begtimeshow:false,
      endtimeshow:false,
      // 身份证示例
      showzz: false,
      // 申报登记
      grade: "请选择",
      columns: ["初级(五级)", "中级(四级)", "高级(三级)"],
      pickershow: false,
      name: "",
      phone: "",
      pardes: {},
      //   性别
      sex: "请选择",
      sexshow: false,
      columnssex: ["男", "女"],
      //   文化程度
      culture: "请选择",
      cultureshow: false,
      columnsculture: [
        "小学",
        "初中",
        "高中",
        "技工学校",
        "中等专业学校",
        "大学专科",
        "大学本科",
        "研究生",
      ],
      //   单位名称
      companyname: "",
      //   工作年限
      years: "",
      //   人资部电话
      telephone: "",
      //   详细地址
      address: "",
      city: "请选择",
      areaList,
      cityshow: false,
      columnscity: [],
      // 证书等级
      zsgrade: "",
      // 证书编号
      zsnumber: "",
      // 取证时间
      zstime: "请选择",
      zstimeshow: false,
      currentDate: new Date(),
      // 发证机构名称
      zsjgname: "",

      //   工作培训经历
      experience: [
        {
          // 开始时间
          begindate: "请选择",
          begtimeshow: false,
          endtimeshow: false,
          currentDate: new Date(),
          changeDate: new Date(),
          // 结束时间
          enddate: "请选择",
          // 单位名称
          unitname: "",
          // 职业
          occupation: "",
          // 证明人
          witness: "",
        },
      ],

      //   底部复选框
      checked: false,
      //   图片
      imga: "",
      imgb: "",
      imgc: "",
      imgd: "",
      imge: "",
      orderid: "",
      // 身份证号
      idcardno: "",
      showa: false,
      show: false,
      shows: false,
      startPos: 0,
      images: [
        require("@/assets/images/signup/sbzmcxff@2x.png"),
        require("@/assets/images/signup/sbzmys@2x.png"),
        require("@/assets/images/signup/图层 6@2x.png"),
        require("@/assets/images/signup/xlbg-sample-2018@2x.png"),
        require("@/assets/images/sfzsc.png"),
      ],
      zhuntai: 1,
      status: "",
      goodsid: "",
      goodsname: "",
      pid: "",
      userid: "",
      es:0
    };
  },

  created() {
    this.infoget()
    this.userid = sessionStorage.getItem("id");
    let codes = sessionStorage.getItem("id");
    if (codes == 222 || codes == "222") {
      this.login();
    } else {
      this.goodsid = this.$route.query.goodsid;
      let pid = this.$route.query.pid;
      if (pid != "" || pid != undefined || pid != null) {
        this.pid = pid;
      }
      this.goods();
      this.checkorders();
      this.orderadd();
      this.timeFormat(new Date());
      this.getgetskillgoodsconditionfind();
    }

    // if(this.$route.query.userid!=''||this.$route.query.userid!=undefined||this.$route.query.userid!=null){
    // this.guanbi();
    // }
  },
  mounted() {},
  methods: {
    infoget() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
      };
      info(data).then((res) => {
        if(res.data.data.phone!=''||res.data.data.phone!=null||res.data.data.phone!=undefined){
          this.phone = res.data.data.phone;
        }else{
          this.phone =""
        }
      });
    },
    timese(e){
      this.es=e
      this.begtimeshow = true
    },
    timeseend(e){
      this.es=e
      this.endtimeshow = true
    },
    confirmFntime(value) {
      this.zstime = this.timeFormat(this.currentDate);
      this.zstimeshow = false;
    },
    goods() {
      let userid = sessionStorage.getItem("id");

      let datas = {
        userid,
      };
      getskillgoodscondition(datas).then((re) => {
        console.log(re);
        for (let i = 0; i < re.data.data.length; i++) {
          if (re.data.data[i].goodsid == this.goodsid) {
            this.goodsname = re.data.data[i].goodsname;
          }
        }
      });
    },
    guanbi() {
      let url = window.location.href;
      console.log(url, "路由地址");
      if (url == "http://lhjy.sjzydrj.net/signup") {
        setTimeout(function () {
          //这个可以关闭安卓系统的手机
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              WeixinJSBridge.call("closeWindow");
            },
            false
          );
          //这个可以关闭ios系统的手机
          WeixinJSBridge.call("closeWindow");
        });
      }
    },
    login() {
      console.log(5);
      var _this = this;
      let url = window.location.href;
      sessionStorage.setItem("urls", url);
      console.log(url);
      let codes = this.$route.query.code;
      let data = {
        // code: '031LTYZv3m78ZY2Uv40w3ypE9A2LTYZx',
        code: codes,
        type: 7,
      };
      indexs(data).then((res) => {
        // let id = '3646690';
        let id = res.data.data.id;
        if (res.data.code) {
          _this.id = id;
          console.log(id, 1);
          sessionStorage.setItem("id", id);
          console.log(sessionStorage.getItem("id"), 123456);
          this.goodsid = this.$route.query.goodsid;
          let pid = this.$route.query.pid;
          if (pid != "" || pid != undefined || pid != null) {
            this.pid = pid;
          }
          this.goods();
          this.checkorders();
          this.orderadd();
          this.timeFormat(new Date());
          this.getgetskillgoodsconditionfind();
        } else {
          let ids = sessionStorage.getItem("id");
          sessionStorage.setItem("id", ids);
        }
      });
    },
    // 驳回
    checkorders() {
      let _this = this;
      let goodsid = this.$route.query.goodsid;
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        goodsid: goodsid,
      };
      checkorder(data).then((res) => {
        console.log(res);
        let datas = res.data.data;
        if (res.data.code) {
          this.status = datas.status;
          this.name = datas.name;
          this.sex = datas.sex;
          this.phone = datas.phone;
          this.culture = datas.education;
          this.idcardno = datas.idcardno;
          this.companyname = datas.company;
          this.years = datas.postage;
          this.city = datas.companyaddress;
          this.address = datas.companydetailaddress;
          this.telephone = datas.companyphone;
          this.grade = datas.levelname;
          this.zsgrade = datas.certificatename;
          this.zsnumber = datas.certificatenumber;
          if (datas.certificatetime == "0000-00-00") {
            this.zstime = "请选择";
          } else {
            this.zstime = datas.certificatetime;
          }

          this.zsjgname = datas.institution;
          this.imga = datas.photopath; //电子照片路径
          this.imgb = datas.idcard_front; //身份证正面
          this.imgc = datas.idcard_back; //身份证反面
          this.imgd = datas.educationpath; //学历证明
          this.imge = datas.ssc; //社保证明
          this.experience = datas.order_detail;
          for (let i = 0; i < this.experience.length; i++) {
            this.experience[i].begtimeshow = false;
            this.experience[i].endtimeshow = false;
            this.experience[i].currentDate = new Date();
            this.experience[i].changeDate = new Date();
          }
          console.log(this.experience,123456)
          this.orderid = datas.orderid;
          this.pid = datas.conditionid;
          let data = {
            userid,
            goodsid: goodsid,
            levelname: datas.levelname,
          };
          getskillgoodslevelfind(data).then((res) => {
            this.pardes = res.data.data;
          });
        }
      });
    },
    // 存储的数据
    orderadd() {
      let orderadd = JSON.parse(sessionStorage.getItem("orderadd"));
      console.log(orderadd);
      if (
        orderadd !== null &&
        orderadd !== "" &&
        orderadd !== undefined &&
        orderadd !== "null"
      ) {
        console.log(123456789);
        this.name = orderadd.name;
        this.sex = orderadd.sex;
        this.phone = orderadd.phone;
        this.culture = orderadd.education;
        this.idcardno = orderadd.idcardno;
        this.companyname = orderadd.company;
        this.years = orderadd.postage;
        this.city = orderadd.companyaddress;
        this.address = orderadd.companydetailaddress;
        this.telephone = orderadd.companyphone;
        this.grade = orderadd.levelname;
        this.zsgrade = orderadd.certificatename;
        this.zsnumber = orderadd.certificatenumber;
        this.zstime = orderadd.certificatetime;
        this.zsjgname = orderadd.institution;
        this.imga = orderadd.photopath; //电子照片路径
        this.imgb = orderadd.idcard_front; //身份证正面
        this.imgc = orderadd.idcard_back; //身份证反面
        this.imgd = orderadd.educationpath; //学历证明
        this.imge = orderadd.ssc; //社保证明
        this.pid = orderadd.conditionid; //社保证明
        this.experience = orderadd.order_detail;
        let userid = sessionStorage.getItem("id");
        let data = {
          userid,
          goodsid: this.goodsid,
          levelname: orderadd.levelname,
        };
        getskillgoodslevelfind(data).then((res) => {
          this.pardes = res.data.data;
        });
      }
    },
    // 查询字典
    getgetskillgoodsconditionfind() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        goodsid: this.goodsid,
      };
      getskillgoodsconditionfind(data).then((res) => {});
    },
    // 报名
    btn() {
      let userid = sessionStorage.getItem("id");
      if (this.name == "") {
        Toast("请输入姓名");
      } else if (this.sex == "请选择") {
        Toast("请选择性别");
      } else if (this.phone == "") {
        Toast("请输入联系电话");
      } else if (this.culture == "请选择") {
        Toast("请选择文化程度");
      } else if (this.idcardno == "") {
        Toast("请输入身份证号");
      }else if (this.imgb == "") {
        Toast("请上传身份证人像面照片");
      } else if (this.imgc == "") {
        Toast("请上传身份证国徽面照片");
      }

      // 修改取消注释
      //  else if (this.companyname == "") {
      //   Toast("请输入工作单位名称");
      // } else if (this.years == "") {
      //   Toast("请输入现从事岗位（年限）");
      // } else if (this.city == "请选择") {
      //   Toast("请选择单位地址");
      // } else if (this.address == "") {
      //   Toast("请输入详细地址地址");
      // } else if (this.grade == "请选择") {
      //   Toast("请选择申报等级");
      // } else if (this.experience[0].begindate == "请选择") {
      //   Toast("请选择开始时间");
      // } else if (this.experience[0].enddate == "请选择") {
      //   Toast("请选择结束时间");
      // } else if (this.experience[0].unitname == "") {
      //   Toast("请输入单位名称");
      // } else if (this.experience[0].occupation == "") {
      //   Toast("请输入从事何种职业（工种）或学习");
      // } else if (this.experience[0].witness == "") {
      //   Toast("请输入证明人");
      // } else if (this.imga == "") {
      //   Toast("请上传电子照片");
      // } else if (this.imgd == "") {
      //   Toast("请上传学历证明照片");
      // } else if (this.imge == "") {
      //   Toast("请上传社保证明照片");
      // }
      
      else if (this.checked == false) {
        Toast(
          "请同意本人自愿参加职业技能等级认定，承诺符合申报技能等级的条件，提供的信息资料真实有效。"
        );
      } else {
        let ares = this.city + this.address;
        let data = {
          userid,
          goodsid: this.goodsid,
          goodsname: this.goodsname,
          name: this.name,
          sex: this.sex,
          phone: this.phone,
          education: this.culture,
          idcardno: this.idcardno,
          company: this.companyname,
          postage: this.years,
          companyaddress: this.city,
          companydetailaddress: this.address,
          companyphone: this.telephone,
          levelname: this.grade,
          certificatename: this.zsgrade,
          certificatenumber: this.zsnumber,
          certificatetime: this.zstime,
          institution: this.zsjgname,
          photopath: this.imga, //电子照片路径
          idcard_front: this.imgb, //身份证正面
          idcard_back: this.imgc, //身份证反面
          educationpath: this.imgd, //学历证明
          ssc: this.imge, //社保证明
          conditionid: this.pid, //满足条件
          order_detail: this.experience,
          orderid: this.orderid,
        };
        orderadd(data).then((res) => {
          if (res.data.code == true) {
            if (this.status == "2") {
              Toast("修改成功");
              this.$router.push("/index");
            } else {
              // 跳转支付
              // sessionStorage.setItem("orderadd", JSON.stringify(data));
              // this.$router.push(
              //   "/pay?orderid=" + res.data.data.orderid + "&id=1" + "&types=1"
              // );

              Toast("报名成功");
              // this.$router.push("/index");
              this.$router.go(-2);
            }
          } else {
            Toast(res.data.msg);
          }
        });
      }
    },
    //   申报登记
    grades() {
      this.pickershow = true;
    },
    onConfirmgrade(value, index) {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        goodsid: this.goodsid,
        levelname: value,
      };
      getskillgoodslevelfind(data).then((res) => {
        this.pardes = res.data.data;
      });
      this.grade = value;
      this.pickershow = false;
    },
    // 性别
    onConfirmgradesex(value, index) {
      this.sex = value;
      this.sexshow = false;
    },
    // 文化程度
    onConfirmgradeculture(value, index) {
      this.culture = value;
      this.cultureshow = false;
    },
    // 单位地址
    onConfirmgradecity(value, index) {
      this.city = value[0].name + "/" + value[1].name + "/" + value[2].name;
      this.cityshow = false;
    },
    // 开始时间
    confirmFn(e) {
      // 确定按钮
      this.experience[e].begindate = this.timeFormat(
        this.currentDate
      );
      this.begtimeshow = false;
    },
    // 结束时间
    confirmFnend(e) {
      console.log(e)
      // 确定按钮
      this.experience[e].enddate = this.timeFormat(
        this.currentDate
      );
      console.log(this.experience[e].enddate)
      this.endtimeshow = false;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    },
    // 添加工作培训经历
    addto() {
      // this.experience
      let exp = {
        // 开始时间
        begindate: "请选择",
        begtimeshow: false,
        currentDate: new Date(),
        changeDate: new Date(),
        // 结束时间
        enddate: "请选择",
        endtimeshow: false,
        // 单位名称
        unitname: "",
        // 职业
        occupation: "",
        // 证明人
        witness: "",
      };
      this.experience.push(exp);
    },

    // 提示确认按钮
    confirm() {
      this.showzz = false;
      this.showa = false;
      this.show = false;
    },
    showzzs() {
      this.showzz = true;
    },
    // 查看图片
    ImagePre(e) {
      this.startPos = e;
      this.shows = true;
    },
    // 查看社保证明
    chakn() {
      this.showa = true;
    },
    onimages(e) {
      this.zhuntai = e;
    },
    // 上传照片
    onReads(file) {
      var _this = this;
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.file);
      fileReader.onload = function (res) {
        let baser64 = this.result;
        let obj = {
          width: 500,
          height: 550,
          getFlag: "6",
        };
        canvasDataURL(baser64, obj, (value) => {
          let file = _this.dataURLtoFile(value, "idcard_front" + ".jpg");
          var formData = new FormData();
          formData.append("file", file);
          getimg(formData).then((res) => {
            if (_this.zhuntai == 1) {
              _this.imga = res.data.data.src;
            } else if (_this.zhuntai == 2) {
              _this.imgb = res.data.data.src;
            } else if (_this.zhuntai == 3) {
              _this.imgc = res.data.data.src;
            } else if (_this.zhuntai == 4) {
              _this.imgd = res.data.data.src;
            } else if (_this.zhuntai == 5) {
              _this.imge = res.data.data.src;
            }
          });
        });
      };
    },
    // base64转为file
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  //   position: relative;
  //   padding: 0 10px;
  .worktype {
    padding: 0 10px;
    .order_r {
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ececec;
      .order_l {
        float: left;
      }
      .order_r {
        font-size: 14px;
        float: right;
        color: rgb(158, 158, 158);
        border: 0;
      }
      .order_z {
        font-size: 16px;
        float: right;
        color: rgb(0, 0, 0);
        border: 0;
      }
      .page_l {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
      .page_r {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
    }
  }
  .titles {
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    height: 50px;
    padding-left: 10px;
    background-color: rgb(231, 231, 231);
  }
  .titlese {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    padding-left: 10px;
    background-color: rgb(231, 231, 231);
    line-height: 30px;
    .teos {
      font-size: 14px;
      color: #0297fd;
      span {
        text-decoration: underline;
      }
    }
  }
  .basics {
    padding: 10px;
    .imasg {
      width: 30%;
      height: 140px;
      display: inline-block;
      .upload-img {
        width: 100%;
        height: 100%;
      }
    }
    .leftbas {
      display: inline-block;
      vertical-align: top;

      // 修改注释
      width: 100%;

      // 修改取消注释
      // width: 67%;
      // margin-left: 3%;
      .order_r {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #ececec;
        .fields {
          font-size: 16px !important;
        }
        .order_l {
          float: left;
          padding-left: 8px;
          color: #646566;
        }
        .order_r {
          font-size: 14px;
          float: right;
          color: rgb(158, 158, 158);
          border: 0;
        }
        .order_z {
          font-size: 16px;
          float: right;
          color: rgb(0, 0, 0);
          border: 0;
        }
        .page_l {
          width: 50%;
          display: inline-block;
          span {
            color: #f00;
          }
        }
        .page_r {
          width: 50%;
          display: inline-block;
          span {
            color: #f00;
          }
        }
      }
    }
    .order_r {
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ececec;
      .fields {
        font-size: 16px !important;
      }
      .order_l {
        float: left;
        padding-left: 8px;
        color: #646566;
      }
      .order_r {
        font-size: 14px;
        float: right;
        color: rgb(158, 158, 158);
        border: 0;
      }
      .order_z {
        font-size: 16px;
        float: right;
        color: rgb(0, 0, 0);
        border: 0;
      }
      .page_l {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
      .page_r {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
    }
    .idimg {
      padding: 0 10px;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .left_img {
        width: 48%;
        height: 150px;
        display: inline-block;
        .top_com {
          font-size: 12px;
          text-align: center;
          padding-bottom: 10px;
        }
        .upload-img {
          width: 100%;
          height: 150px;
          //   padding-top: 10px;
        }
      }
    }
  }
  .gzdwxx {
    padding: 0 10px;
    .order_r {
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ececec;

      .fields {
        font-size: 16px !important;
        .van-cell__title {
          width: 8.2rem !important;
        }
      }
      .order_l {
        float: left;
        padding-left: 8px;
        color: #646566;
      }
      .order_r {
        font-size: 14px;
        float: right;
        color: rgb(158, 158, 158);
        border: 0;
      }
      .files {
        width: 50%;
      }
      .order_z {
        font-size: 16px;
        float: right;
        color: rgb(0, 0, 0);
        border: 0;
      }
      .page_l {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
      .page_r {
        width: 50%;
        display: inline-block;
        span {
          color: #f00;
        }
      }
    }
  }
  .bianli {
    border-bottom: 1px solid #c0c0c0;
    margin-top: 15px;
  }
  .bianlis {
    border-bottom: 1px solid #c0c0c0;
    margin-top: 0px;
  }
  .addto {
    text-align: center;
    font-size: 16px;
    color: #0297fd;
    line-height: 50px;
    img {
      width: 20px;
      height: 20px;
      padding-right: 10px;
      transform: translateY(4px);
    }
  }
  .checkbox {
    font-size: 13px;
    padding: 0 10px;
  }
  .btn {
    padding: 20px 10px;
    .butts {
      background-color: #1989f8;
      font-size: 18px;
      color: #fff;
      border: 0;
      border-radius: 5px;
      width: 100%;
      line-height: 50px;
    }
  }
  .dialog {
    max-height: 70%;
    overflow-y: auto;
    .com_d {
      padding: 20px;
      font-size: 14px;
      color: #171717;
      .images {
        width: 100%;
        margin-top: 10px;
      }
      .sty {
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
      }
      .zhuy {
        color: #f00;
        font-size: 12px;
      }
      .imag {
        width: 60%;
        margin-left: 20%;
      }
    }
  }
}
.van-dialog {
  top: 52% !important;
}
.van-field__label {
  color: #000000 !important;
}
</style>
