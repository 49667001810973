// import canvasDataURL from "@/assets/img.js";
// 
// let fileReader = new FileReader();
// var _this=this
// fileReader.onload = function () {
//   let baser64 = this.result;
//   let obj = {
//     width: 358,
//     height: 441,
//     getFlag: "6",
//   };
//   canvasDataURL(baser64, obj, (value) => {
//     console.log(value,12356)
//   });


// 图片压缩
export const imgPreview = (file, callback) => {
  //将base64转换为文件
  function dataURLtoFile(dataurl, file) {
    var arr = dataurl.split(","),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], file.name, {
      type: file.type,
    });
  }

  // 看支持不支持FileReader
  if (!file || !window.FileReader) {
    return;
  }
  if (/^image/.test(file.type)) {
    // 创建一个reader
    let reader = new FileReader();
    // 将图片转成 base64 格式
    reader.readAsDataURL(file);
    // 读取成功后的回调
    reader.onloadend = function() {
      let result = this.result;
      let img = new Image();
      img.src = result;
      //判断图片是否大于500K,是就直接上传，反之压缩图片
      if (this.result.length <= 100 * 1024) {
        // 上传图片
        let imageFile = dataURLtoFile(this.result, file);
        callback(imageFile);
      } else {
        img.onload = function() {
          let data = compress(img);
          // 上传图片
          let imageFile = dataURLtoFile(data, file);
          callback(imageFile);
        };
      }
    };
  }
};

export default function canvasDataURL(path, obj = 550, callback) {
  //   console.log(obj);
  var img = new Image();
  img.src = path;
  img.onload = function() {
    var that = this;
    // 默认按比例压缩
    var w = that.width,
      h = that.height,
      scale = w / h;
    w = obj.width || w;
    // h = obj.height || (w / scale);
    h = w / scale;
    if (obj.getFlag === "6") {
      console.log(666);
      var quality = 0.8; // 默认图片质量为0.7
    } else {
      var quality = 0.8; // 默认图片质量为0.7
    }

    //生成canvas
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    // 创建属性节点
    var anw = document.createAttribute("width");
    anw.nodeValue = w;
    var anh = document.createAttribute("height");
    anh.nodeValue = h;
    canvas.setAttributeNode(anw);
    canvas.setAttributeNode(anh);
    ctx.drawImage(that, 0, 0, w, h);
    // 图像质量
    if (obj.quality && obj.quality <= 0.8 && obj.quality > 0) {
      quality = obj.quality;
    }
    // quality值越小，所绘制出的图像越模糊
    var base64 = canvas.toDataURL("image/jpeg", quality);
    // console.log(base64)

    // 回调函数返回base64的值
    callback(base64);
  };
}
